@import '@styles/mixins.style';

.accommodation-carousel {
  position: relative;
  width: 1200px;
  max-width: 100%;

  .swiper {
    &-slide {
      max-height: 100%;
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      overflow: hidden;
      @include breakpoint(md) {
        height: 90vh !important;
      }

      img {
        object-fit: contain;
        width: 100%;
        height: auto;
        max-height: 100% !important;
        @include breakpoint(md) {
          max-width: 75%;
        }
      }
    }
  }

  &__controls {
    display: none;
    @include breakpoint(md) {
      display: block;
      position: absolute;
      left: 0;
      top: 50%;
      width: 100%;
      transform: translateY(-50%);
      display: flex;
      justify-content: space-between;
      align-items: center;
      pointer-events: none;
      z-index: 2;
      button {
        pointer-events: all;
      }
    }
  }

  &__pagination {
    display: block;
    position: absolute;
    bottom: -30px;
    color: var(--color-light);
    z-index: 999 !important;
    padding-inline: var(--ra-spacing-3);
    left: 50%;
    transform: translateX(-50%);
    margin-inline: auto;
    border-radius: 8px;
  }
}
