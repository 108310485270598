.simple-carousel {
  position: relative;
  max-width: 100%;
  overflow: hidden;

  .swiper {
    width: 100%;
    max-height: 100%;
    max-width: 100%;

    &-slide {
      width: 100% !important;

      img {
        width: auto;
        width: 100%;
      }
    }
  }

  &__controls {
    display: grid;
    grid-template-columns: 1fr 120px;
    align-items: center;
    gap: var(--ra-spacing-6);
    margin-top: var(--ra-spacing-4);

    &__progress {
      position: relative;
      width: 100%;
      height: 1px;
      background: rgba(37, 45, 56, 0.24);

      &::after {
        position: absolute;
        content: '';
        top: 0;
        left: 0;
        width: var(--progress-bar);
        height: 2px;
        background: var(--color-primary);
        transition: width 250ms ease;
      }
    }

    &__buttons {
      display: flex;
      justify-content: space-evenly;
      min-height: 50px;
    }
  }
}
