@import '@styles/mixins.style';

.product-cards-carousel {
  .swiper {
    min-height: 300px;
    .swiper-wrapper {
      padding-block: var(--ra-spacing-6);
      .swiper-slide {
        width: auto !important;
        height: auto !important;
      }
    }
  }

  &__controls {
    display: grid;
    grid-template-columns: 1fr 120px;
    align-items: center;
    gap: var(--ra-spacing-4);

    @include breakpoint(md) {
      gap: var(--ra-spacing-7);
    }

    &__progress {
      position: relative;
      width: 100%;
      height: 1px;
      background: rgba(37, 45, 56, 0.24);

      &::after {
        position: absolute;
        content: '';
        top: 0;
        left: 0;
        width: var(--progress-bar);
        height: 2px;
        background: var(--color-primary);
        transition: width 250ms ease;
      }
    }

    &__buttons {
      display: flex;
      justify-content: space-evenly;
      min-height: 50px;
    }
  }
}
