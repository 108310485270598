.mobile-cards-carousel {
  position: relative;
  max-width: 100%;
  overflow: hidden;
  display: flex;
  justify-content: center;

  .swiper {
    padding-block: var(--ra-spacing-2);

    &-slide {
      max-height: 300px;
      * {
        width: 100%;
        height: auto;
        height: 100%;
      }
      img {
        object-fit: cover;
      }
    }
  }

  &__controls {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    margin-block: auto;
    width: 100%;
    display: flex;
    justify-content: space-between;
    z-index: 20;

    button {
      margin-inline: var(--ra-spacing-2);
    }
  }
}
